<template>
  <section>
    <v-container>
<!--      <v-img :src="'https://s3.us-east-1.wasabisys.com/sea2sea/media/StoreFront.png'" height="700"></v-img>-->
      <div class="my-4">
        <div class="my-10">
          <span class="friendly-3">The Brands at our Retail Location</span>
        </div>
        <v-row class="mb-10">
          <v-col v-for="(brand, index) in brands" :key="index" sm="3">
            <div>
              <v-card class="rounded-lg">
                <v-sheet style="width: 100%; height: 100%" class="grey">
                  <v-img :src="brand.logo" width="150px" height="150px" contain class="mx-auto" :alt="brand.name"></v-img>
                </v-sheet>
                <v-card-text style="height: 200px;" class="friendly-1">
                  {{ brand.about }}
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="my-4">
        <div class="my-10">
          <div class="friendly-3">Rental Information</div>
          <div class="friendly-1 mt-2">We offer a range of equipment available for rent. Pricing and availability is available in-store.</div>
        </div>
        <v-row>
          <v-col v-for="(product, index) in rentals" :key="index" sm="3">
            <div>
              <v-card class="rounded-lg">
                <v-img :src="product.image" class="mx-auto" :alt="product.name"></v-img>
                <v-card-title class="friendly-1">
                  {{ product.name }}
                </v-card-title>
                <v-card-text style="height: 80px;" class="friendly-1">
                  {{ product.description }}
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Equipment',
  data: () => ({
    brands: [
      {
        name: 'Akona',
        logo: 'https://www.akona.com/GetSiteFile/AKONA_logo.png',
        about: 'Akona is a renowned brand that excels in creating top-notch dive gear and accessories for scuba diving enthusiasts.'
      },
      {
        name: 'Analox',
        logo: 'https://www.analoxgroup.com/wp-content/uploads/2021/10/Analox-Logo.png',
        about: 'Analox is a leading brand that specializes in producing innovative gas analysis equipment and safety devices for the diving industry. '
      },
      {
        name: 'Apeks',
        logo: 'https://us.apeksdiving.com/on/demandware.static/Sites-apeksdivingus-Site/-/default/dwd8715128/images/logo-blue.svg',
        about: 'Apeks is a trusted brand that focuses on designing and manufacturing high-performance regulators, dive computers, and other dive gear for recreational and technical diving.'
      },
      {
        name: 'Aqua Lung',
        logo: 'https://us.aqualung.com/on/demandware.static/Sites-aqualungus-Site/-/default/dw32f8ac34/images/logo-blue.svg',
        about: 'Aqua Lung is a prominent brand that specializes in creating a wide range of dive gear and equipment, including regulators, buoyancy compensators, wetsuits, and more, for divers of all skill levels.'
      },
      {
        name: 'Atomic',
        logo: 'https://www.atomicaquatics.com/wp-content/uploads/2017/09/ATOMIC_LOGO.png',
        about: 'Atomic is a premium brand that specializes in crafting top-of-the-line dive gear, including regulators, fins, masks, and snorkels, with a focus on advanced technology, performance, and durability.'
      },
      {
        name: 'Bare',
        logo: 'https://www.baresports.com/wp-content/uploads/2020/03/bare-wordmark-black-200.png',
        about: 'Bare is a renowned brand that specializes in designing and manufacturing high-quality wetsuits, drysuits, and other thermal protection gear for scuba diving, snorkeling, and other water sports.'
      },
      {
        name: 'Fourth Element',
        logo: 'https://fourthelement.com/images/felogo.svg',
        about: 'Fourth Element is a well-known brand that specializes in producing eco-friendly and sustainable dive gear, including wetsuits, drysuits, rashguards, and accessories, with a focus on design, innovation, and conservation.'
      },
      {
        name: 'Henderson',
        logo: 'https://hendersonusa.com/wp-content/uploads/thinlogo.png',
        about: 'Henderson is a trusted brand that specializes in creating high-quality wetsuits, boots, gloves, hoods, and other thermal protection gear for divers and other water sports enthusiasts, with a focus on comfort, durability, and performance.'
      },
      {
        name: 'Hollis',
        logo: 'https://www.hollis.com/wp-content/uploads/sites/5/2020/05/Hollis-Logo.png',
        about: 'Hollis is a renowned brand that specializes in designing and manufacturing high-performance scuba diving gear and equipment, including regulators, buoyancy compensators, masks, fins, lights, and other accessories, with a focus on innovation, quality, and reliability.'
      },
      {
        name: 'Neosport',
        logo: 'https://neosportusa.com/files/2018/04/cropped-logo-192x192.png',
        about: 'Neosport is a reputable brand that specializes in creating affordable, yet high-quality wetsuits, boots, gloves, and other thermal protection gear for divers, snorkelers, and other water sports enthusiasts, with a focus on comfort, durability, and value.'
      },
      {
        name: 'Oceanic',
        logo: 'https://www.oceanicworldwide.com/wp-content/uploads/sites/6/2022/10/OC_WordMark-300x39-1.png',
        about: 'Oceanic is a leading brand that specializes in producing high-quality dive gear and accessories, including regulators, dive computers, masks, fins, and other equipment, with a focus on advanced technology, performance, and reliability for divers of all skill levels.'
      },
      {
        name: 'PADI',
        logo: 'https://www.padi.com/themes/custom/bootstrap_padi/public/images/logo_padi_dark.svg',
        about: 'PADI is a globally recognized brand that specializes in providing high-quality scuba diving education and training, including certifications, courses, and materials, with a focus on safety, professionalism, and environmental conservation.'
      },
      {
        name: 'ScubaPro',
        logo: 'https://scubapro.johnsonoutdoors.com/sites/default/files/SCUBAPRO-logo_1.png',
        about: 'ScubaPro is a premium brand that specializes in creating top-of-the-line scuba diving equipment and gear, including regulators, dive computers, wetsuits, fins, masks, and other accessories, with a focus on quality, innovation, and performance for divers of all levels.'
      },
      {
        name: 'Shearwater',
        logo: 'https://www.shearwater.com/wp-content/themes/Shearwater2017/images/logo.png',
        about: 'Shearwater is a reputable brand that specializes in producing high-quality dive computers for recreational and technical divers, with a focus on advanced technology, user-friendly interfaces, and customizable features, making them one of the most trusted brands in the diving industry.'
      },
      {
        name: 'Stahlsac',
        logo: 'https://www.stahlsac.com/wp-content/uploads/sites/2/2021/08/stahlsac-logo.png',
        about: 'Stahlsac is a respected brand that specializes in creating durable, functional, and innovative dive bags and luggage, including backpacks, roller bags, duffels, and mesh bags, with a focus on convenience, protection, and style for divers and travelers.'
      },
      {
        name: 'Suunto',
        logo: 'https://www.suunto.com/Static/dist/svg/suunto-logo.860416b.svg',
        about: 'Suunto is a leading brand that specializes in producing high-performance dive computers, sports watches, and precision instruments for adventurers, athletes, and explorers, with a focus on accuracy, durability, and innovation, making them a trusted choice for divers and outdoor enthusiasts.'
      },
      {
        name: 'X Deep',
        logo: 'https://www.xdeep.eu/img/logo.png',
        about: 'X-Deep is a renowned brand that specializes in creating high-quality and innovative scuba diving equipment, including buoyancy compensators, wing systems, harnesses, and other accessories, with a focus on functionality, comfort, and performance for technical and recreational divers.'
      }
    ],
    rentals: [
      {
        name: 'Buoyancy compensator (BCD)',
        description: 'A device that helps you control your buoyancy underwater.',
        image: ''
      },
      {
        name: 'Regulator',
        description: 'A device that connects to the tank and delivers air to the diver.',
        image: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/regulator.jpeg'
      },
      {
        name: 'Tanks',
        description: 'Contains compressed air or other breathing gases.',
        image: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/tanks.jpeg'
      },
      {
        name: 'Wetsuits',
        description: 'A thermal protection suit that keeps the diver warm in colder water.',
        image: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/wetsuit.jpeg'
      },
      {
        name: 'Weights',
        description: "Used to adjust the diver's buoyancy.",
        image: ''
      }
    ]
  }),
  created () {
    this.doSetDocumentTitle('Retail Store', true)
  }
}
</script>

<style scoped>

</style>
